import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const FormRadioGroup= ({input: {onChange, value}, meta, label, color='inherit', options, row=true}) => {
    // TODO: Fix Form control label styling
    return (
    <FormControl>
      <FormLabel sx={{display: 'flex', justifyContent: 'flex-start', '&.Mui-focused':{color:color}}}>{label}</FormLabel>
      <RadioGroup
        row={row}
      >
          {
              options.map((option, idx)=>{

                  return(
                    <FormControlLabel
                        key={idx}
                        value={option.value}
                        control={
                            <Radio
                                sx={{
                                     "&.Mui-checked": {
                                        color: color,
                                      },

                                }}
                            />
                        }
                        label={option.label}
                        onClick={()=> {
                            onChange(option.value);
                        }}
                    />
                  )
              })
          }
      </RadioGroup>
     {meta.error && meta.modified && meta.dirty && (
            <label
                style={{
                    color: '#d29292',
                    fontSize: '12px',
                    display: 'block',
                    fontWeight: 400,
                    marginTop: '3px',
                    textAlign: 'left',
                }}
            >
                {meta.error}
            </label>
        )}
    </FormControl>
  );
}

export default FormRadioGroup;